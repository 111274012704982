<template>
  <div class="column-center guide-root-container">
    <div
      class="column-center guide-content-width"
      style="background-color: #ffffff; padding: 0px 50px"
    >
      <span class="guide-page-title" style="margin-top: 30px"> 参展指导 </span>
      <img
        :src="icons.intro_triangle2"
        height="15"
        style="margin-top: 10px; margin-bottom: 55px"
      />

      <span class="guide-label"> 常见问题Q&A </span>
      <div
        class="guide-qa-container"
        v-for="(item, index) in qaList"
        :key="item.qa_id"
      >
        <div class="guide-qa-question">{{ index + 1 }}.{{ item.question }}</div>
        <div class="guide-qa-answer">{{ item.answer }}</div>
      </div>

      <span class="guide-label"> 官网&公众号报名流程 </span>
      <div
        class="row-between guide-content-width"
        style="width: 100%; margin: 50px 0px"
      >
        <img
          :src="official_website_img_url"
          style="width: 300px; cursor: pointer"
          @click="clickWebsite"
        />
        <img
          :src="official_account_img_url"
          style="width: 300px; cursor: pointer"
          @click="clickAccount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,

      qaList: [],

      page_size: 1000,
      page_index: 0,

      official_website_url: "",
      official_account_url: "",
      official_website_img_url: "",
      official_account_img_url: "",
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.getList();
    this.getOfficialAccount();
    this.getOfficialWebsite();
  },
  methods: {
    ...mapActions({
      qaGetListAction: "qaGetList",
      positionGetContentAction: "positionGetContent",
    }),
    getList() {
      this.qaGetListAction({
        page_size: this.page_size,
        page_index: this.page_index,
      })
        .then((res) => {
          this.qaList = res.data;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getOfficialWebsite() {
      this.positionGetContentAction({
        position_id: 4,
      }).then((res) => {
        this.official_website_url = res.link;
        this.official_website_img_url = res.img_url;
      });
    },
    getOfficialAccount() {
      this.positionGetContentAction({
        position_id: 5,
      }).then((res) => {
        this.official_account_url = res.link;
        this.official_account_img_url = res.img_url;
      });
    },
    clickWebsite() {
      window.location.href = this.official_website_url;
    },
    clickAccount() {
      window.location.href = this.official_account_url;
    },
  },
};
</script>

<style>
.guide-root-container {
  background: #f0e6d4;
}
.guide-content-width {
  width: 800px;
}
.guide-background {
  width: 368px;
  height: 368px;
}
.guide-page-english {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.guide-page-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.guide-label {
  /* font-family: PingFangSC-Semibold; */
  font-size: 32px;
  color: #0065b8;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  margin-top: 55px;
}
.guide-content-container {
  margin-top: 15px;
  width: 305px;
  padding: 15px;
  background: #f83a02;
  border-radius: 5px;
}
.guide-content {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
}
.guide-download {
  cursor: pointer;
  text-decoration: underline;
}
.guide-qa-container {
  margin-top: 30px;
  align-self: flex-start;
}
.guide-qa-question {
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
}
.guide-qa-answer {
  white-space: pre-wrap;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
</style>